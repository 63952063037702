import axios from "axios";
import Config from "../../config";
import RefreshTokenHandler from './refresh-token';

const getCompanies = async (email) => {
  try{
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/companies?email=${email}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
  }catch(err) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await getCompanies(email)
      }else {
        throw err;
      }
    }else {
          throw err
    }
  }
};
const getAllEventData = async (
  email,
) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/statutoryevents/?email=${email}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getAllEventData(email,
         
          );
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};
const getPaginatedEventData = async (
  email,
  page_number,
  page_size,
  // company_code
) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/statutoryevents/paginate?page_size=10&email=${email}&page_number=${page_number}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getPaginatedEventData(email,
          page_number,
          page_size,
          );
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};

const getPaginatedEventDataByCompany = async (
  email,
  page_number,
  // page_size,
  company_code
) => {
  try {
    const token = sessionStorage.getItem("token") || "";
    const url = `${Config.baseUrl}/statutoryevents/paginate-by-company?page_size=10&email=${email}&page_number=${page_number}&company_code=${company_code}`;
    const result = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });
    return result;
  } catch (err) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getPaginatedEventDataByCompany(email,
          page_number,
          // page_size,
          company_code
          );
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
};
const getFolioByCounter = async (email, company_code) => {
  try{
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/companies/folio-counter?email=${email}&company_code=${company_code}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
  }catch(err) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await getFolioByCounter(email, company_code)
      }else {
        throw err;
      }
    }else {
          throw err
    }
  }
};
const getCertificateNo = async (email, company_code) => {
  try{
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/companies/share-counter?email=${email}&company_code=${company_code}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
}catch(err) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getCertificateNo(email, company_code)
    }else {
      throw err;
    }
  }else {
        throw err
  }
}
};

const getCompanyById = async (email, id) => {
  try{
  const token = sessionStorage.getItem("token") || "";
  const url = `${Config.baseUrl}/companies/by-id?email=${email}&code=${id}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token,
    },
  });
  return result;
}catch(err) {
  if(err.response.data.status == 401) { 
    let responseToHandle = err.response.data
    let result = await RefreshTokenHandler.handleIt(responseToHandle)
    if(result.status) {
     return await getCompanyById(email, id)
    }else {
      throw err;
    }
  }else {
        throw err
  }
}
};

const addCompany = async (
  email,
  code,
  company_name,
  isin,
  registered_name,
  symbol,
  company_secretary,
  parent_code,
  active,
  ntn,
  incorporation_no,
  sector_code,
  website,
  contact_person_name,
  contact_person_phone,
  contact_person_exchange_no,
  contact_person_email,
  ceo_name,
  ceo_phone,
  ceo_mobile,
  ceo_email,
  head_office_address,
  head_office_city,
  head_office_country,
  outstanding_shares,
  face_value,
  total_shares,
  free_float,
  treasury_shares,
  preference_shares,
  ordinary_shares,
  non_voting_shares,
  redeemable_shares,
  management_shares,
  company_type,
  authorized_persons,
  governance,
  allot_size
) => {
  try{
  const url = `${Config.baseUrl}/companies/`;

  const result = await axios.post(
    url,
    {
      email,
      code,
      company_name,
      isin,
      registered_name,
      symbol,
      company_secretary,
      parent_code,
      active,
      ntn,
      incorporation_no,
      sector_code,
      website,
      contact_person_name,
      contact_person_phone,
      contact_person_exchange_no,
      contact_person_email,
      ceo_name,
      ceo_phone,
      ceo_mobile,
      ceo_email,
      head_office_address,
      head_office_city,
      head_office_country,
      outstanding_shares,
      face_value,
      total_shares,
      free_float,
      treasury_shares,
      preference_shares,
      ordinary_shares,
      non_voting_shares,
      redeemable_shares,
      management_shares,
      company_type,
      authorized_persons,
      governance,
      allot_size,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
  }catch(err) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await addCompany(
        email,
        code,
        company_name,
        isin,
        registered_name,
        symbol,
        company_secretary,
        parent_code,
        active,
        ntn,
        incorporation_no,
        sector_code,
        website,
        contact_person_name,
        contact_person_phone,
        contact_person_exchange_no,
        contact_person_email,
        ceo_name,
        ceo_phone,
        ceo_mobile,
        ceo_email,
        head_office_address,
        head_office_city,
        head_office_country,
        outstanding_shares,
        face_value,
        total_shares,
        free_float,
        treasury_shares,
        preference_shares,
        ordinary_shares,
        non_voting_shares,
        redeemable_shares,
        management_shares,
        company_type,
        authorized_persons,
        governance,
        allot_size
       )
      }else {
        throw err;
      }
    }else {
          throw err
    }
  }
};

const updateCompany = async (
  email,
  code,
  company_name,
  isin,
  registered_name,
  symbol,
  company_secretary,
  parent_code,
  active,
  ntn,
  incorporation_no,
  sector_code,
  website,
  contact_person_name,
  contact_person_phone,
  contact_person_exchange_no,
  contact_person_email,
  ceo_name,
  ceo_phone,
  ceo_mobile,
  ceo_email,
  head_office_address,
  head_office_city,
  head_office_country,
  outstanding_shares,
  face_value,
  total_shares,
  free_float,
  treasury_shares,
  preference_shares,
  ordinary_shares,
  non_voting_shares,
  redeemable_shares,
  management_shares,
  company_type,
  authorized_persons,
  governance,
  allot_size
) => {
  try{
  const url = `${Config.baseUrl}/companies/update`;

  const result = await axios.post(
    url,
    {
      email,
      code,
      company_name,
      isin,
      registered_name,
      symbol,
      company_secretary,
      parent_code,
      active,
      ntn,
      incorporation_no,
      sector_code,
      website,
      contact_person_name,
      contact_person_phone,
      contact_person_exchange_no,
      contact_person_email,
      ceo_name,
      ceo_phone,
      ceo_mobile,
      ceo_email,
      head_office_address,
      head_office_city,
      head_office_country,
      outstanding_shares,
      face_value,
      total_shares,
      treasury_shares,
      free_float,
      preference_shares,
      ordinary_shares,
      non_voting_shares,
      redeemable_shares,
      management_shares,
      company_type,
      authorized_persons,
      governance,
      allot_size,
    },
    {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    }
  );
  return result;
  }catch(err) {
    if(err.response.data.status == 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await updateCompany(
        email,
        code,
        company_name,
        isin,
        registered_name,
        symbol,
        company_secretary,
        parent_code,
        active,
        ntn,
        incorporation_no,
        sector_code,
        website,
        contact_person_name,
        contact_person_phone,
        contact_person_exchange_no,
        contact_person_email,
        ceo_name,
        ceo_phone,
        ceo_mobile,
        ceo_email,
        head_office_address,
        head_office_city,
        head_office_country,
        outstanding_shares,
        face_value,
        total_shares,
        free_float,
        treasury_shares,
        preference_shares,
        ordinary_shares,
        non_voting_shares,
        redeemable_shares,
        management_shares,
        company_type,
        authorized_persons,
        governance,
        allot_size
       )
      }else {
        throw err;
      }
    }else {
          throw err
    }
  }
};
const sendEmail = async (
  email,
  code,
  to,
  cc,
  subject,
  template
) => {
  try {
       const url = `${Config.baseUrl}/companies/manual-statutory-alert-template?email=${email}&code=${code}&to[]=${to}&cc[]=${cc}&subject=${subject}&template=${template}`;
    const result = await axios.get(
      url,
      {
        headers: {
          Authorization: sessionStorage.getItem("token") || ""
        },
      }
    );
    return result;
  } catch (err) {
    if(err.response.data.status === 401) { 
      let responseToHandle = err.response.data
      let result = await RefreshTokenHandler.handleIt(responseToHandle)
      if(result.status) {
       return await sendEmail(
        email,
        code,
        to,
        cc,
        subject,
        template
       )
      }else {
        throw err;
      }
    }else {
          throw err
    }
  }
 
};

export {
  getCompanies,
  getCertificateNo,
  addCompany,
  updateCompany,
  getCompanyById,
  getFolioByCounter,
  getPaginatedEventData,
  sendEmail,
  getPaginatedEventDataByCompany,
  getAllEventData
};
