import {
  Activity,
  Home,
  Box,
  Server,
  Chrome,
  // DollarSign,
  // UserPlus,
  // Users,

  // Settings,
  Airplay,
  // FolderPlus,
  File,
  // Command,
  Cloud,
  // Book,
  // FileText,

  // Image,
  // Sliders,
  // Map,
  // GitPullRequest,
  // Calendar,
  // Edit,
  // Mail,
  // MessageSquare,
  // UserCheck,
  Layers,
  // HelpCircle,
  // Database,
  // Headphones,
  // Mic,
  // ShoppingBag,
  // Search,
  // AlertOctagon,
  // Lock,
  // Briefcase,
  // BarChart,
  // Target,
  // List,
  Package,
  Users,
  Paperclip,
} from "react-feather";

export const MENUITEMS = [
  {
    title: "Dashboard",
    icon: Home,
    classname: "icon-home mr-3",
    type: "link",
    path: "/admindashboard",
    badgeType: "primary",
    active: false,
    // children: [{ path: "/dashboard/default", title: "Default", type: "link" }],
  },

  // {
  //   title: "Company",
  //   icon: Box,
  //   classname: "icon-package mr-3",
  //   type: "sub",
  //   badgeType: "primary",
  //   active: false,
  //   children: [
  //     // {
  //     //   path: "/company-listing",
  //     //   title: "Company Listing",
  //     //   type: "link",
  //     // },
  //     {
  //       path: "/company-info",
  //       title: "Company Info",
  //       type: "link",
  //     },
  //     // {
  //     //   path: "/bank-account-listing",
  //     //   title: "Bank Account Listing",
  //     //   type: "link",
  //     // },
  //   ],
  // },
  // {
  //   title: "Shareholders",
  //   icon: Activity,
  //   classname: "icon-infinite mr-3",
  //   type: "sub",
  //   badgeType: "primary",
  //   active: false,
  //   children: [
  //     // {
  //     //   path: "/investors-listing",
  //     //   title: "Investors Listing",
  //     //   type: "link",
  //     // },
  //     // {
  //     //   path: "/shareholder-request-listing",
  //     //   title: "Shareholder Requests",
  //     //   type: "link",
  //     // },
  //     // {
  //     //   path: "/investors-request-report",
  //     //   title: "Investors Request",
  //     //   type: "link",
  //     // },
  //   ],
  // },

  {
    title: "Shareholdings",
    icon: Chrome,
    classname: "icon-money mr-3",

    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      // {
      //   path: "/physical-file-uploader",
      //   title: "Physical File Uploader",
      //   type: "link",
      // },
      // {
      //   path: "/certificate-file-uploader",
      //   title: "Certiifcate File Uploader",
      //   type: "link",
      // },
      // {
      //   path: "/share-listing",
      //   title: "Public Offering",
      //   type: "link",
      // },
      // {
      //   path: "/share-certificate-listing",
      //   title: "Share Certificates",
      //   type: "link",
      // },
      {
        path: "/shareholder-listing",
        title: "Shareholding",
        type: "link",
      },
      {
        path: "/category-of-shareholding",
        title: "Categories of Holders",
        type: "link",
      },
      {
        path: "/pattern-holding",
        title: "Shareholding Pattern",
        type: "link",
      },
      {
        path: "/free-float-report",
        title: "Free Float Report",
        type: "link",
      },
      
      {
        path: "/shareholding-history",
        title: "Shareholding History",
        type: "link",
      },
      {
        path: "/investors-request-report",
        title: "Investors Requests",
        type: "link",
      },
      
      
      // {
      //   path: "/shareholding-uploader",
      //   title: "CDC File Uploader",
      //   type: "link",
      // },
    ],
  },
  // {
  //   title: "Transaction",
  //   icon: Server,
  //
  // type: "sub",
  //   badgeType: "primary",
  //   active: false,
  //   children: [
  //     {
  //       path: "/transaction-listing",
  //       title: "Transaction Listing",
  //       type: "link",
  //     },
  //     // {
  //     //   path: "/transaction-type-listing",
  //     //   title: "Transaction Type Listing",
  //     //   type: "link",
  //     // },
  //   ],
  // },
  {
    title: "Transactions",
    icon: Layers,
    classname: "icon-bar-chart mr-3",
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      {
        path: "/transaction-listing",
        title: "Transaction",
        type: "link",
      },
      {
        path: "/inprocess-transactions",
        title: "In Process",
        type: "link",
      },
      // {
      //   path: "/accepted-requests",
      //   title: "Accepted Requests",
      //   type: "link",
      // },
      // {
      //   path: "/rejected-requests",
      //   title: "Rejected Requests",
      //   type: "link",
      // },
    ],
  },
  // {
  //   title: "Checklist",
  //   icon: File,
  //
  // type: "sub",
  //   badgeType: "primary",
  //   active: false,
  //   children: [
  //     {
  //       path: "/process-checklist-listing",
  //       title: "Process Checklist Listing",
  //       type: "link",
  //     },
  //     {
  //       path: "/checklist-item-listing",
  //       title: "Checklist Item Listing",
  //       type: "link",
  //     },
  //   ],
  // },
  {
    title: "Statutory",
    icon: Airplay,
    classname: "icon-announcement mr-3",
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      {
        path: "/statutory-events",
        title: "Statutory Event",
        type: "link",
      },
    ]
  },
  {
    title: "Governance",
    icon: Airplay,
    classname: "icon-money mr-3",
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      {
        path: "/elections-of-directors-listing",
        title: "Elections Of Directors",
        type: "link",
      },
      {
        path: "/candidates-listing",
        title: "Candidates",
        type: "link",
      },
      {
        path: "/election-voting",
        title: "Election Voting",
        type: "link",
      },
      {        
        path: "/election-voting-report",
        title: "Election Voting Report",
        type: "link",
      },
      {
        path: "/compile-elections-results",
        title: "Compile Election Results",
        type: "link",
      },
      {
        path: "/proxy-and-authorization-listing",
        title: "Proxy & Authorization",
        type: "link",
      },
      {
        path: "/special-resolution-listing",
        title: "Special Resolution",
        type: "link",
      },
      {
        path: "/special-voting",
        title: "Special Voting",
        type: "link",
      },
      {
        path: "/special-voting-report",
        title: "Special Voting Report",
        type: "link",
      },
      {
        path: "/compile-resolution-results",
        title: "Compile Resolution Results",
        type: "link",
      },
      
    ]
  },
  {
    title: "Corporate Actions",
    icon: Airplay,
    classname: "icon-announcement mr-3",
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      {
        path: "/corporate-action-calculator",
        title: "Action Calculator",
        type: "link",
      },
      {
        path: "/corporate-announcement-listing",
        title: "Announcements",
        type: "link",
      },
      {
        path: "/corporate-entitlement-listing",
        title: "Entitlements",
        type: "link",
      },
      {
        path: "/disbursement-listing",
        title: "Disbursements",
        type: "link",
      },
      {
        path: "/right-allotment-report",
        title: "Right Allotment",
        type: "link",
      },
      {
        path: "/bonus-allotment-report",
        title: "Bonus Allotment",
        type: "link",
      },
      {
        path: "/dividend-allotment-report",
        title: "Dividend Allotment",
        type: "link",
      },
      {
        path: "/list-of-shareholders",
        title: "List of Shareholding",
        type: "link",
      }
    ],
  },
  // {
  //   title: "Charges",
  //   icon: Package,
  //
  //type: "sub",
  //   badgeType: "primary",
  //   active: false,
  //   children: [
  //     {
  //       path: "/charges-listing",
  //       title: "Charges Listing",
  //       type: "link",
  //     },
  //   ],
  // },
  // {
  //   title: "User Management",
  //   icon: Users,
  //   classname: "icon-user mr-3",
  //   type: "sub",
  //   badgeType: "primary",
  //   active: false,
  //   children: [
  //     {
  //       path: "/roles-listing",
  //       title: "Roles Listing",
  //       type: "link",
  //     },
  //   ],
  // },
  // {
  //   title: "Dividend Disbursement",
  //   icon: Cloud,
  //   classname: "icon-control-shuffle mr-3",
  //   type: "sub",
  //   badgeType: "primary",
  //   active: false,
  //   children: [
  //     {
  //       path: "/disbursement-listing",
  //       title: "Disbursement Listing",
  //       type: "link",
  //     },
  //   ],
  // },

  // {
  //   title: "Reporting",
  //   icon: Paperclip,
  //   classname: "icon-files mr-3",

  //   type: "sub",
  //   badgeType: "primary",
  //   active: false,
  //   children: [
  //     {
  //       path: "/shareholding-history",
  //       title: "Shareholding History",
  //       type: "link",
  //     },
  //     {
  //       path: "/right-allotment-report",
  //       title: "Right Allotment",
  //       type: "link",
  //     },
  //     {
  //       path: "/bonus-allotment-report",
  //       title: "Bonus Allotment",
  //       type: "link",
  //     },
  //     {
  //       path: "/dividend-allotment-report",
  //       title: "Dividend Allotment",
  //       type: "link",
  //     },
  //     {
  //       path: "/investors-request-report",
  //       title: "Investors Request",
  //       type: "link",
  //     },
  //   ],
  // },
];












// import {
//   Activity,
//   Home,
//   Box,
//   Server,
//   Chrome,
//   // DollarSign,
//   // UserPlus,
//   // Users,

//   // Settings,
//   Airplay,
//   // FolderPlus,
//   File,
//   // Command,
//   Cloud,
//   // Book,
//   // FileText,

//   // Image,
//   // Sliders,
//   // Map,
//   // GitPullRequest,
//   // Calendar,
//   // Edit,
//   // Mail,
//   // MessageSquare,
//   // UserCheck,
//   Layers,
//   // HelpCircle,
//   // Database,
//   // Headphones,
//   // Mic,
//   // ShoppingBag,
//   // Search,
//   // AlertOctagon,
//   // Lock,
//   // Briefcase,
//   // BarChart,
//   // Target,
//   // List,
//   Package,
//   Users,
//   Paperclip,
// } from "react-feather";

// export const MENUITEMS = [
//   // {
//   //   title: "Dashboard",
//   //   icon: Home,
//   //   classname: "icon-home mr-3",
//   //   type: "link",
//   //   path: "/admindashboard",
//   //   badgeType: "primary",
//   //   active: false,
//   //   // children: [{ path: "/dashboard/default", title: "Default", type: "link" }],
//   // },

//   // {
//   //   title: "Company",
//   //   icon: Box,
//   //   classname: "icon-package mr-3",
//   //   type: "sub",
//   //   badgeType: "primary",
//   //   active: false,
//   //   children: [
//   //     // {
//   //     //   path: "/company-listing",
//   //     //   title: "Company Listing",
//   //     //   type: "link",
//   //     // },
//   //     {
//   //       path: "/company-info",
//   //       title: "Company Info",
//   //       type: "link",
//   //     },
//   //     // {
//   //     //   path: "/bank-account-listing",
//   //     //   title: "Bank Account Listing",
//   //     //   type: "link",
//   //     // },
//   //   ],
//   // },
//   // {
//   //   title: "Shareholders",
//   //   icon: Activity,
//   //   classname: "icon-infinite mr-3",
//   //   type: "sub",
//   //   badgeType: "primary",
//   //   active: false,
//   //   children: [
//   //     // {
//   //     //   path: "/investors-listing",
//   //     //   title: "Investors Listing",
//   //     //   type: "link",
//   //     // },
//   //     // {
//   //     //   path: "/shareholder-request-listing",
//   //     //   title: "Shareholder Requests",
//   //     //   type: "link",
//   //     // },
//   //     // {
//   //     //   path: "/investors-request-report",
//   //     //   title: "Investors Request",
//   //     //   type: "link",
//   //     // },
//   //   ],
//   // },

//   // {
//   //   title: "Shareholdings",
//   //   icon: Chrome,
//   //   classname: "icon-money mr-3",

//   //   type: "sub",
//   //   badgeType: "primary",
//   //   active: false,
//   //   children: [
//   //     // {
//   //     //   path: "/physical-file-uploader",
//   //     //   title: "Physical File Uploader",
//   //     //   type: "link",
//   //     // },
//   //     // {
//   //     //   path: "/certificate-file-uploader",
//   //     //   title: "Certiifcate File Uploader",
//   //     //   type: "link",
//   //     // },
//   //     // {
//   //     //   path: "/share-listing",
//   //     //   title: "Public Offering",
//   //     //   type: "link",
//   //     // },
//   //     // {
//   //     //   path: "/share-certificate-listing",
//   //     //   title: "Share Certificates",
//   //     //   type: "link",
//   //     // },
//   //     {
//   //       path: "/shareholder-listing",
//   //       title: "Shareholding",
//   //       type: "link",
//   //     },
//   //     {
//   //       path: "/category-of-shareholding",
//   //       title: "Categories of Holders",
//   //       type: "link",
//   //     },
//   //     {
//   //       path: "/pattern-holding",
//   //       title: "Shareholding Pattern",
//   //       type: "link",
//   //     },
//   //     {
//   //       path: "/free-float-report",
//   //       title: "Free Float Report",
//   //       type: "link",
//   //     },
      
//   //     {
//   //       path: "/shareholding-history",
//   //       title: "Shareholding History",
//   //       type: "link",
//   //     },
//   //     {
//   //       path: "/investors-request-report",
//   //       title: "Investors Requests",
//   //       type: "link",
//   //     },
      
      
//   //     // {
//   //     //   path: "/shareholding-uploader",
//   //     //   title: "CDC File Uploader",
//   //     //   type: "link",
//   //     // },
//   //   ],
//   // },
//   // {
//   //   title: "Transaction",
//   //   icon: Server,
//   //
//   // type: "sub",
//   //   badgeType: "primary",
//   //   active: false,
//   //   children: [
//   //     {
//   //       path: "/transaction-listing",
//   //       title: "Transaction Listing",
//   //       type: "link",
//   //     },
//   //     // {
//   //     //   path: "/transaction-type-listing",
//   //     //   title: "Transaction Type Listing",
//   //     //   type: "link",
//   //     // },
//   //   ],
//   // },
//   // {
//   //   title: "Transactions",
//   //   icon: Layers,
//   //   classname: "icon-bar-chart mr-3",
//   //   type: "sub",
//   //   badgeType: "primary",
//   //   active: false,
//   //   children: [
//   //     {
//   //       path: "/transaction-listing",
//   //       title: "Transaction",
//   //       type: "link",
//   //     },
//   //     {
//   //       path: "/inprocess-transactions",
//   //       title: "In Process",
//   //       type: "link",
//   //     },
//   //     // {
//   //     //   path: "/accepted-requests",
//   //     //   title: "Accepted Requests",
//   //     //   type: "link",
//   //     // },
//   //     // {
//   //     //   path: "/rejected-requests",
//   //     //   title: "Rejected Requests",
//   //     //   type: "link",
//   //     // },
//   //   ],
//   // },
//   // {
//   //   title: "Checklist",
//   //   icon: File,
//   //
//   // type: "sub",
//   //   badgeType: "primary",
//   //   active: false,
//   //   children: [
//   //     {
//   //       path: "/process-checklist-listing",
//   //       title: "Process Checklist Listing",
//   //       type: "link",
//   //     },
//   //     {
//   //       path: "/checklist-item-listing",
//   //       title: "Checklist Item Listing",
//   //       type: "link",
//   //     },
//   //   ],
//   // },
//   // {
//   //   title: "Statutory",
//   //   icon: Airplay,
//   //   classname: "icon-announcement mr-3",
//   //   type: "sub",
//   //   badgeType: "primary",
//   //   active: false,
//   //   children: [
//   //     {
//   //       path: "/statutory-events",
//   //       title: "Statutory Event",
//   //       type: "link",
//   //     },
//   //   ]
//   // },
//   {
//     title: "Governance",
//     icon: Airplay,
//     classname: "icon-money mr-3",
//     type: "sub",
//     badgeType: "primary",
//     active: false,
//     children: [
//       // {
//       //   path: "/elections-of-directors-listing",
//       //   title: "Elections Of Directors",
//       //   type: "link",
//       // },
//       // {
//       //   path: "/candidates-listing",
//       //   title: "Candidates",
//       //   type: "link",
//       // },
//       // {
//       //   path: "/proxy-and-authorization-listing",
//       //   title: "Proxy & Authorization",
//       //   type: "link",
//       // },
//       {
//         path: "/election-voting-report",
//         title: "Election Voting Report",
//         type: "link",
//       },
//       // {
//       //   path: "/election-voting",
//       //   title: "Election Voting",
//       //   type: "link",
//       // },
//       // {
//       //   path: "/special-voting",
//       //   title: "Special Voting",
//       //   type: "link",
//       // },
//       // {
//       //   path: "/special-resolution-listing",
//       //   title: "Special Resolution",
//       //   type: "link",
//       // },
//     ]
//   },
//   // {
//   //   title: "Corporate Actions",
//   //   icon: Airplay,
//   //   classname: "icon-announcement mr-3",
//   //   type: "sub",
//   //   badgeType: "primary",
//   //   active: false,
//   //   children: [
//   //     {
//   //       path: "/corporate-action-calculator",
//   //       title: "Action Calculator",
//   //       type: "link",
//   //     },
//   //     {
//   //       path: "/corporate-announcement-listing",
//   //       title: "Announcements",
//   //       type: "link",
//   //     },
//   //     {
//   //       path: "/corporate-entitlement-listing",
//   //       title: "Entitlements",
//   //       type: "link",
//   //     },
//   //     {
//   //       path: "/disbursement-listing",
//   //       title: "Disbursements",
//   //       type: "link",
//   //     },
//   //     {
//   //       path: "/right-allotment-report",
//   //       title: "Right Allotment",
//   //       type: "link",
//   //     },
//   //     {
//   //       path: "/bonus-allotment-report",
//   //       title: "Bonus Allotment",
//   //       type: "link",
//   //     },
//   //     {
//   //       path: "/dividend-allotment-report",
//   //       title: "Dividend Allotment",
//   //       type: "link",
//   //     },
//   //     {
//   //       path: "/list-of-shareholders",
//   //       title: "List of Shareholding",
//   //       type: "link",
//   //     }
//   //   ],
//   // },
//   // {
//   //   title: "Charges",
//   //   icon: Package,
//   //
//   //type: "sub",
//   //   badgeType: "primary",
//   //   active: false,
//   //   children: [
//   //     {
//   //       path: "/charges-listing",
//   //       title: "Charges Listing",
//   //       type: "link",
//   //     },
//   //   ],
//   // },
//   // {
//   //   title: "User Management",
//   //   icon: Users,
//   //   classname: "icon-user mr-3",
//   //   type: "sub",
//   //   badgeType: "primary",
//   //   active: false,
//   //   children: [
//   //     {
//   //       path: "/roles-listing",
//   //       title: "Roles Listing",
//   //       type: "link",
//   //     },
//   //   ],
//   // },
//   // {
//   //   title: "Dividend Disbursement",
//   //   icon: Cloud,
//   //   classname: "icon-control-shuffle mr-3",
//   //   type: "sub",
//   //   badgeType: "primary",
//   //   active: false,
//   //   children: [
//   //     {
//   //       path: "/disbursement-listing",
//   //       title: "Disbursement Listing",
//   //       type: "link",
//   //     },
//   //   ],
//   // },

//   // {
//   //   title: "Reporting",
//   //   icon: Paperclip,
//   //   classname: "icon-files mr-3",

//   //   type: "sub",
//   //   badgeType: "primary",
//   //   active: false,
//   //   children: [
//   //     {
//   //       path: "/shareholding-history",
//   //       title: "Shareholding History",
//   //       type: "link",
//   //     },
//   //     {
//   //       path: "/right-allotment-report",
//   //       title: "Right Allotment",
//   //       type: "link",
//   //     },
//   //     {
//   //       path: "/bonus-allotment-report",
//   //       title: "Bonus Allotment",
//   //       type: "link",
//   //     },
//   //     {
//   //       path: "/dividend-allotment-report",
//   //       title: "Dividend Allotment",
//   //       type: "link",
//   //     },
//   //     {
//   //       path: "/investors-request-report",
//   //       title: "Investors Request",
//   //       type: "link",
//   //     },
//   //   ],
//   // },
// ];
