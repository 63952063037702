// import React, { useEffect, useState, Suspense, lazy } from "react";
// import ReactDOM from "react-dom";
// import "./index.scss";
// import { firebase_app, auth0 } from "./data/config";
// import {
//   configureFakeBackend,
//   authHeader,
//   handleResponse,
// } from "./services/fack.backend";
// import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
// import * as serviceWorker from "./serviceWorker";
// import AuthGuard from "./auth/AuthGuard";
// // * Import custom components for redux *
// import { Provider } from "react-redux";
// import store from "./store";
// //config data
// import configDB from "./data/customizer/config";

// import Callback from "./auth/callback";
// import Spinner from "./components/common/spinner";

// // Import custom Components

// // import share registrar
// import ForgetPassword from "./auth/forgetPassword";
// import Signin from "./auth/signin";
// import AdminDashboard from "./components/dashboard1/adminDashboard";

// import App from "./components/app";
// import ViewCompany from "components/shareRegistrar/company/viewCompany";
// // const InvestorsListing = lazy(() =>
// //   import("./components/shareRegistrar/investors/investorsListing")
// // );
// // const InvestorRequestListing = lazy(() =>
// //   import(
// //     "./components/shareRegistrar/investors/investorRequest/investorRequestListing"
// //   )
// // );
// // const ShareholderListing = lazy(() =>
// //   import("./components/shareRegistrar/shareholders/shareholderListing")
// // );
// // const StatutoryEvent = lazy(() =>
// //   import("./components/shareRegistrar/Staturtory/statyaryEvent")
// // );

// // const ShareListing = lazy(() =>
// //   import("./components/shareRegistrar/share/shareListing")
// // );

// // const AnnouncementListing = lazy(() =>
// //   import(
// //     "./components/shareRegistrar/corporate/announcement/announcementListing"
// //   )
// // );
// // const EntitlementListing = lazy(() =>
// //   import("./components/shareRegistrar/corporate/entitlement/entitlementListing")
// // );
// // const ActionCalculator = lazy(() =>
// //   import("./components/shareRegistrar/corporate/action-calculator")
// // );
// // const DisbursementListing = lazy(() =>
// //   import("./components/shareRegistrar/dividend/disbursementListing")
// // );
// // const ShareCertificateIssuanceListing = lazy(() =>
// //   import(
// //     "./components/shareRegistrar/share-certificate/shareCertificateIssuanceListing"
// //   )
// // );
// // const TransactionRequestListing = lazy(() =>
// //   import("./components/shareRegistrar/processing/transactionRequestListing")
// // );
// // const TransactionListings = lazy(() =>
// //   import("./components/shareRegistrar/transactionListing/transactionListing")
// // );
// // const ShareholdingHistory = lazy(() =>
// //   import("./components/reporting/shareholdingHistory")
// // );
// // const RightAllotmentReport = lazy(() =>
// //   import("./components/reporting/rightAllotmentReport")
// // );
// // const InvestorRequestReport = lazy(() =>
// //   import("./components/reporting/investorsRequestReport")
// // );
// // const CategoryOfShareholding = lazy(() =>
// //   import("./components/reporting/categoryofShareholding")
// // );
// // const FreeFloat = lazy(() =>
// //   import("./components/reporting/freeFloatReport")
// // );
// // const ListOfShareholders = lazy(() =>
// //   import("./components/reporting/list-of-shareholders")
// // );
// // const BonusAllotmentReport = lazy(() =>
// //   import("./components/reporting/bonusAllotmentReport")
// // );
// // const DividendAllotmentReport = lazy(() =>
// //   import("./components/reporting/dividendReport")
// // );

// // const PatternHolding = lazy(() =>
// //   import("./components/shareRegistrar/pattern-holding/patternHolding")
// // );
// // const ElectionOfDirectorListing = lazy(() =>
// //   import("./components/shareRegistrar/Evoting/ElectionOfDirectorListing")
// // );
// // const CandidateListing = lazy(() =>
// //   import("./components/shareRegistrar/Evoting/electionOfCanidate")
// // );
// // const AuthorizationListing = lazy(() =>
// //   import("./components/shareRegistrar/Evoting/authorizationListing")
// // );
// const DirectorVotingReport = lazy(() =>
//   import("./components/shareRegistrar/Evoting/directorVotingReport")
// );
// // const ListingofElection = lazy(() =>
// //   import("./components/shareRegistrar/Evoting/listingOFElection")
// // );
// // const SpecialVotingListing = lazy(() =>
// //   import("./components/shareRegistrar/Evoting/specialVotingListing")
// // );
// // const ListOfSpecialResolutionListing = lazy(() =>
// //   import("./components/shareRegistrar/Evoting/ListofSpecialResolution")
// // );
// // import EditAnnouncement from "./components/shareRegistrar/corporate/announcement/editAnnouncement";

// // setup fake backend
// // configureFakeBackend();

// const Root = () => {
//   const abortController = new AbortController();
//   const [authenticated, setAuthenticated] = useState(false);

//   useEffect(() => {
//     const color = localStorage.getItem("color");
//     // const layout =
//     //   localStorage.getItem("layout_version") ||
//     //   configDB.data.color.layout_version;
//     const layout = "light" || configDB.data.color.layout_version;

//     // firebase_app.auth().onAuthStateChanged(setCurrentUser);
//     setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
//     document.body.classList.add(layout);
//     console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
//     console.disableYellowBox = true;
//     document
//       .getElementById("color")
//       .setAttribute(
//         "href",
//         `${process.env.PUBLIC_URL}/assets/css/${color}.css`
//       );

//     return function cleanup() {
//       abortController.abort();
//     };

//     // eslint-disable-next-line
//   }, []);

//   return (
//     <div className="App">
//       <Provider store={store}>
//         <BrowserRouter basename={`/`}>
//           <Switch>
//             <Suspense fallback={<Spinner />}>
//               <Route
//                 exact
//                 path={`${process.env.PUBLIC_URL}/`}
//                 render={() => {
//                   return <Redirect to={`${process.env.PUBLIC_URL}/login`} />;
//                 }}
//               />
//               <Route
//                 exact
//                 path={`${process.env.PUBLIC_URL}/login`}
//                 component={Signin}
//               />
//               <Route
//                 exact
//                 path={`${process.env.PUBLIC_URL}/forget-password`}
//                 component={ForgetPassword}
//               />
//               <App>
//                 <Suspense fallback={<Spinner />}>
//                   {/* <AuthGuard
//                   path={}
//                     render={() => {
//                       return (
//                         <Redirect
//                           to={`${process.env.PUBLIC_URL}/election-voting-report`}
//                         />
//                       );
//                     }}
//                   /> */}
//                   <AuthGuard
//                     exact
//                     path={`${process.env.PUBLIC_URL}/election-voting-report`}
//                     component={DirectorVotingReport}
//                   />
//                   <Route path="*" render={() => sessionStorage.getItem("token") ? <Redirect to={`${process.env.PUBLIC_URL}/election-voting-report`} /> : <Redirect to={`${process.env.PUBLIC_URL}/login`} />} />
//                 </Suspense>
//               </App>
//             </Suspense>
//           </Switch>
//         </BrowserRouter>
//       </Provider>
//     </div>
//   );
// };

// ReactDOM.render(<Root />, document.getElementById("root"));

// serviceWorker.unregister();





import React, { useEffect, useState, Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { firebase_app, auth0 } from "./data/config";
import {
  configureFakeBackend,
  authHeader,
  handleResponse,
} from "./services/fack.backend";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import AuthGuard from "./auth/AuthGuard";
// * Import custom components for redux *
import { Provider } from "react-redux";
import store from "./store";
//config data
import configDB from "./data/customizer/config";

import Callback from "./auth/callback";
import Spinner from "./components/common/spinner";

// Import custom Components

// import share registrar
import ForgetPassword from "./auth/forgetPassword";
import Signin from "./auth/signin";
import AdminDashboard from "./components/dashboard1/adminDashboard";

import App from "./components/app";
import ViewCompany from "components/shareRegistrar/company/viewCompany";
const InvestorsListing = lazy(() =>
  import("./components/shareRegistrar/investors/investorsListing")
);
const InvestorRequestListing = lazy(() =>
  import(
    "./components/shareRegistrar/investors/investorRequest/investorRequestListing"
  )
);
const ShareholderListing = lazy(() =>
  import("./components/shareRegistrar/shareholders/shareholderListing")
);
const StatutoryEvent = lazy(() =>
  import("./components/shareRegistrar/Staturtory/statyaryEvent")
);

const ShareListing = lazy(() =>
  import("./components/shareRegistrar/share/shareListing")
);

const AnnouncementListing = lazy(() =>
  import(
    "./components/shareRegistrar/corporate/announcement/announcementListing"
  )
);
const EntitlementListing = lazy(() =>
  import("./components/shareRegistrar/corporate/entitlement/entitlementListing")
);
const ActionCalculator = lazy(() =>
  import("./components/shareRegistrar/corporate/action-calculator")
);
const DisbursementListing = lazy(() =>
  import("./components/shareRegistrar/dividend/disbursementListing")
);
const ShareCertificateIssuanceListing = lazy(() =>
  import(
    "./components/shareRegistrar/share-certificate/shareCertificateIssuanceListing"
  )
);
const TransactionRequestListing = lazy(() =>
  import("./components/shareRegistrar/processing/transactionRequestListing")
);
const TransactionListings = lazy(() =>
  import("./components/shareRegistrar/transactionListing/transactionListing")
);
const ShareholdingHistory = lazy(() =>
  import("./components/reporting/shareholdingHistory")
);
const RightAllotmentReport = lazy(() =>
  import("./components/reporting/rightAllotmentReport")
);
const InvestorRequestReport = lazy(() =>
  import("./components/reporting/investorsRequestReport")
);
const CategoryOfShareholding = lazy(() =>
  import("./components/reporting/categoryofShareholding")
);
const FreeFloat = lazy(() =>
  import("./components/reporting/freeFloatReport")
);
const ListOfShareholders = lazy(() =>
  import("./components/reporting/list-of-shareholders")
);
const BonusAllotmentReport = lazy(() =>
  import("./components/reporting/bonusAllotmentReport")
);
const DividendAllotmentReport = lazy(() =>
  import("./components/reporting/dividendReport")
);

const PatternHolding = lazy(() =>
  import("./components/shareRegistrar/pattern-holding/patternHolding")
);
const ElectionOfDirectorListing = lazy(() =>
  import("./components/shareRegistrar/Evoting/ElectionOfDirectorListing")
);
const CandidateListing = lazy(() =>
  import("./components/shareRegistrar/Evoting/electionOfCanidate")
);
const AuthorizationListing = lazy(() =>
  import("./components/shareRegistrar/Evoting/authorizationListing")
);
const DirectorVotingReport = lazy(() =>
  import("./components/shareRegistrar/Evoting/directorVotingReport")
);
const CompileElectionResult = lazy(() =>
  import("./components/shareRegistrar/Evoting/CompileElectionResult")
)
const ListingofElection = lazy(() =>
  import("./components/shareRegistrar/Evoting/listingOFElection")
);
const SpecialVotingListing = lazy(() =>
  import("./components/shareRegistrar/Evoting/specialVotingListing")
);
const ListOfSpecialResolutionListing = lazy(() =>
  import("./components/shareRegistrar/Evoting/ListofSpecialResolution")
);
const SpecialVotingReport = lazy(() =>
  import("./components/shareRegistrar/Evoting/SpecialVotingReport")
);

const CompileSpecialResolutionResult = lazy(() =>
  import("./components/shareRegistrar/Evoting/CompileSpecialResolutionResult")
);
// import EditAnnouncement from "./components/shareRegistrar/corporate/announcement/editAnnouncement";

// setup fake backend
// configureFakeBackend();

const Root = () => {
  const abortController = new AbortController();
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const color = localStorage.getItem("color");
    // const layout =
    //   localStorage.getItem("layout_version") ||
    //   configDB.data.color.layout_version;
    const layout = "light" || configDB.data.color.layout_version;

    // firebase_app.auth().onAuthStateChanged(setCurrentUser);
    setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    document.body.classList.add(layout);
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    document
      .getElementById("color")
      .setAttribute(
        "href",
        `${process.env.PUBLIC_URL}/assets/css/${color}.css`
      );

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter basename={`/`}>
          <Switch>
            <Suspense fallback={<Spinner />}>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/`}
                render={() => {
                  return <Redirect to={`${process.env.PUBLIC_URL}/login`} />;
                }}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/login`}
                component={Signin}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/forget-password`}
                component={ForgetPassword}
              />
              <App>
                <Suspense fallback={<Spinner />}>
                  <AuthGuard
                    exact
                    path={`/`}
                    render={() => {
                      return (
                        <Redirect
                          to={`${process.env.PUBLIC_URL}/admindashboard`}
                        />
                      );
                    }}
                  />

                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/admindashboard`}
                    component={AdminDashboard}
                  />

                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/company-info`}
                    component={ViewCompany}
                  />

                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/pattern-holding`}
                    component={PatternHolding}
                  />
                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/investors-listing`}
                    component={InvestorsListing}
                  />

                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/shareholder-request-listing`}
                    component={InvestorRequestListing}
                  />
                  {/* Transaction Requests*/}
                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/inprocess-transactions`}
                    component={TransactionRequestListing}
                  />
                  {/* Transaction Listing*/}
                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/transaction-listing`}
                    component={TransactionListings}
                  />

                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/shareholder-listing`}
                    component={ShareholderListing}
                  />
                  {/* Statutory Event */}

                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/statutory-events`}
                    component={StatutoryEvent}
                  />
                 {/*  Staturtory Event End */}
                 {/* Governance Listing Pages start */}
                 <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/elections-of-directors-listing`}
                    component={ElectionOfDirectorListing}
                  />
                   <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/candidates-listing`}
                    component={CandidateListing}
                  />
                    <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/proxy-and-authorization-listing`}
                    component={AuthorizationListing}
                  />
                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/election-voting-report`}
                    component={DirectorVotingReport}
                  />
                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/compile-elections-results`}
                    component={CompileElectionResult}
                  />
                    <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/election-voting`}
                    component={ListingofElection}
                  />
                    <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/special-voting`}
                    component={SpecialVotingListing}
                  />
                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/compile-resolution-results`}
                    component={CompileSpecialResolutionResult}
                  />
                   <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/special-voting-report`}
                    component={SpecialVotingReport}
                  />
                  
                    <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/special-resolution-listing`}
                    component={ListOfSpecialResolutionListing}
                  />
                  {/* Governance Listing Pages End */}
                  {/* Reporting  Start*/}
                  {/* Shareholding History*/}
                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/shareholding-history`}
                    component={ShareholdingHistory}
                  />
                  {/* Right Allotment History*/}
                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/right-allotment-report`}
                    component={RightAllotmentReport}
                  />
                  {/* Bonus Allotment History*/}
                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/bonus-allotment-report`}
                    component={BonusAllotmentReport}
                  />
                  {/* Dividend Allotment History*/}
                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/dividend-allotment-report`}
                    component={DividendAllotmentReport}
                  />
                  {/* Category Of Shareholding Report*/}
                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/category-of-shareholding`}
                    component={CategoryOfShareholding}
                  />
                  {/* Category Of Shareholding Report*/}
                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/free-float-report`}
                    component={FreeFloat}
                  />
                  {/* List Of Shareholding Report*/}
                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/list-of-shareholders`}
                    component={ListOfShareholders}
                  />
                  {/* Investors Request Report*/}
                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/investors-request-report`}
                    component={InvestorRequestReport}
                  />
                  {/* Reporting End*/}
                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/share-certificate-listing`}
                    component={ShareCertificateIssuanceListing}
                  />
                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/share-listing`}
                    component={ShareListing}
                  />

                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/corporate-announcement-listing`}
                    component={AnnouncementListing}
                  />
                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/corporate-action-calculator`}
                    component={ActionCalculator}
                  />
                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/corporate-entitlement-listing`}
                    component={EntitlementListing}
                  />

                  <AuthGuard
                    exact
                    path={`${process.env.PUBLIC_URL}/disbursement-listing`}
                    component={DisbursementListing}
                  />
                </Suspense>
              </App>
            </Suspense>
          </Switch>
        </BrowserRouter>
      </Provider>
    </div>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
