import React, { Fragment, useState } from "react";
import { useHistory } from "react-router";
import {
  ShoppingBag,
  Download,
  AlertCircle,
  Bell,
  MoreHorizontal,
} from "react-feather";
import { Modal, ModalHeader, ModalBody, UncontrolledTooltip, ModalFooter } from "reactstrap";
import { Notification, All } from "../../../constant";
import { useSelector } from "react-redux";
import { getFoundObject } from "../../../utilities/utilityFunctions";
import ViewTransactionRequest from "../../shareRegistrar/processing/viewTransactionRequest";
import NotificationModal from "./notificationmodal";
import { GrFormClose } from 'react-icons/gr'
// import {
//   AlignLeft,
//   Maximize,
//   Bell,
//   MessageCircle,
//   MoreHorizontal,
// } from "react-feather";
const Notifications = (props) => {
  const transaction_requests = useSelector((data) => data.TransactionRequests);
  const announcements = useSelector((data) => data.Announcements);
  const entitlements = useSelector((data) => data.Entitlements);
  const shareholders = useSelector((data) => data.Shareholders);
  const { company_data, company_data_loading } = useSelector(
    (data) => data.Company
  );
  const history = useHistory();
  const [viewFlag, setViewFlag] = React.useState(false);
  const [data, setData] = React.useState('');
  const [index, setIndex] = React.useState();
  let [count, setCount] = React.useState(0);


  const [array, setArray] = useState([
    {
      name: 'Form A needs to be submitted because annual return of company having share capital',
      dis: 'Form A is need to be submitted'
    }, {
      name: 'Form 3 needs to be submitted because return of allotments of shares',
      dis: 'Form 3 is need to be submitted'
    }, {
      name: 'Form 3A needs to be submitted because change of more than twenty five percent in shareholding or membership or voting rights',
      dis: 'Form 3A is need to be submitted'
    },
  ])

  return (
    <Fragment>
      <li className="onhover-dropdown">
        <div className="media align-items-center">
          <Bell />

          <div className="dotted-animation">
            {
              count === 0 && (
                <>
                <span className="animate-circle"></span>
                <span className="main-circle"></span>
                </>
              )
            }
          </div>

        </div>
        <div>

          <ul
            className="notification-dropdown onhover-show-div p-0"
            style={{ maxHeight: "50vh", minHeight: '20vh', overflow: "scroll" }}
          >
            <li className="txt-dark ">Recent Alerts</li>
            {transaction_requests.transaction_request_data
              .sort((a, b) => b.txn_id - a.txn_id)
              .slice(0, 5)
              .map((item, i) => (
                <li
                  key={i}
                  onClick={() => {
                    const obj = JSON.parse(JSON.stringify(item));
                    obj.announcement_id = getFoundObject(
                      announcements.announcement_dropdown,
                      obj.announcement_id
                    );
                    obj.entitlement_id = getFoundObject(
                      entitlements.entitlement_dropdown,
                      obj.entitlement_id
                    );
                    obj.from_folio = getFoundObject(
                      shareholders.shareholders_dropdown,
                      obj.from_folio
                    );
                    // obj.symbol = getFoundObject(symbol_options, obj.symbol);
                    obj.txn_type = getFoundObject(
                      transaction_requests.transaction_request_types,
                      obj.txn_type
                    );
                    obj.folio_number = getFoundObject(
                      shareholders.shareholders_dropdown,
                      obj.folio_number
                    );
                    obj.company_code = {
                      label: company_data?.company_name,
                      value: company_data?.code,
                    };
                    // for modal
                    setViewFlag(true);
                    sessionStorage.setItem(
                      "selectedTransactionRequest",
                      JSON.stringify(obj)
                    );
                  }}
                >
                  <div className="media">
                    <div className="media-body">
                      <h6 className="mt-0 text-nowrap ml-1">
                        <span>
                          <AlertCircle className="font-danger" />
                        </span>
                        {`ID: ${item.txn_id}`}
                      </h6>
                      <p className="mb-0">
                        <b>Transaction Type:</b>
                        {` ${transaction_requests.transaction_request_types.find(
                          (tem) => tem.value === item.txn_type
                        )?.label
                          }`}
                      </p>
                      <p>
                        <b>Requester Folio:</b>
                        {` ${shareholders.shareholders_dropdown.find(
                          (tem) => tem.value === item.folio_number
                        )?.label
                          }`}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            {
              array.map((item, i) => {
                return (
                  <li onClick={() => {
                    setViewFlag(true);
                    setIndex(i);
                    count += 1;
                    setCount(count);
                    setData(item.name);
                  }}>
                    <div className="media">
                      <div className="media-body">
                        <h6 className="mt-0 text-nowrap ml-1">
                          <span>
                            <MoreHorizontal className="font-danger" />
                          </span>
                          {
                            item.dis
                          }
                        </h6>
                      </div>
                    </div>
                  </li>
                )
              })
            }

            {/* <li onClick={() => {
                setViewFlag(true);
                setData(array[1].name);
              }}>
                <div className="media">
                  <div className="media-body">
                    <h6 className="mt-0 text-nowrap ml-1">
                      <span>
                        <MoreHorizontal className="font-danger" />
                      </span>
                      Form 3 is need to be submitted
                    </h6>
                  </div>
                </div>
              </li> */}
            {/* <li onClick={() => {
                setViewFlag(true);
                setData(array[2].name);
              }}>
                <div className="media">
                  <div className="media-body">
                    <h6 className="mt-0 text-nowrap ml-1">
                      <span>
                        <MoreHorizontal className="font-danger" />
                      </span>
                      Form A3 is need to be submitted
                    </h6>
                  </div>
                </div>
              </li> */}

            {/* <li>
            {Notification}{" "}
            <span className="badge badge-pill badge-primary pull-right">
              {"3"}
            </span>
          </li> */}
          </ul>
        </div>
      </li>
      <Modal isOpen={viewFlag} show={viewFlag.toString()} size="lg">
        <div className="d-flex xs-width-100 mt-4 justify-content-between">
          <div className="ml-4"><h5 style={{ fontWeight: '600', fontSize: '18px' }}>ALERT</h5></div>
          <div className="mr-4">
            <GrFormClose size={20} style={{ cursor: 'pointer' }}
              onClick={() => {
                setViewFlag(false);
              }}
            />
          </div>
          {/* <ModalHeader
            toggle={() => {
              setViewFlag(false);
            }}
          >
          </ModalHeader> */}
        </div>
        <hr style={{ width: '100%' }} />
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group mb-3">
                <label>{data}</label>
              </div>
            </div>
          </div>
          {/* <NotificationModal data={data} /> */}
        </ModalBody>
        <ModalFooter
        >
          <div className="row">
            <div className="col-md-12 ">
              <button
                type="submit"
                className="btn mr-3"
                onClick={() => {
                  setViewFlag(false)
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-danger"
                onClick={() => {
                  setViewFlag(false);
                  array.splice(index, 1)
                }}
              >
                Dismiss
              </button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Notifications;
