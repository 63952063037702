import {
  DASHBOARD_BEGIN_LOADING,
  DASHBOARD_END_LOADING,
  GET_DASHBOARD_DATA,
} from "../actionTypes";

const initial_state = {
  dashboard_loading: false,
  dashboard_data: {},
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA:
      return { ...state, dashboard_data: action.payload };
    case DASHBOARD_BEGIN_LOADING:
      return { ...state, dashboard_loading: true };
    case DASHBOARD_END_LOADING:
      return { ...state, dashboard_loading: false };
    default:
      return state;
  }
};
