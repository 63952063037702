import { call, put, takeEvery, select } from "redux-saga/effects";

import {
  getCompanies,
  getCompanyById,
} from "../../../store/services/company.service";

import {
  beginCompanyLoading,
  endCompanyLoading,
  fetchCompanies,
  fetchCompaniesDropDown,
  fetchCompaniesSymbolDropDown,
  fetchCompany,
} from "./action";
import { toast } from "react-toastify";
import {
  COMPANY_BEGIN_LOADING,
  COMPANY_END_LOADING,
  GET_COMPANY,
  WATCH_COMPANY,
} from "../../actionTypes";

const email = sessionStorage.getItem("email") || "";
const company_code = sessionStorage.getItem("company_code") || "";

function* fetchCompanyAPI() {
  yield put({ type: COMPANY_BEGIN_LOADING });
  const company = yield call(getCompanyById, email, company_code);
  yield put(fetchCompany(company.data.data));
  yield put({ type: COMPANY_END_LOADING });
}

export function* watcherCompanies() {
  yield takeEvery(WATCH_COMPANY, fetchCompanyAPI);
}
