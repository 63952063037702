import { env } from "../env";
const Config = {
  appTitle: `${env.REACT_APP_TITLE}`,
  appVersion: `${env.REACT_APP_VERSION}`,
  baseUrl: `${env.REACT_APP_BASE_URL}`,
  socketBaseUrl:`${env.REACT_APP_SOCKET_URL}` , //deployed
  imageBaseUrl: `${env.REACT_APP_IMAGE_URL}`, //forimage
  authBaseUrl: `${env.REACT_APP_AUTH_URL}`,
};
export default Config;
