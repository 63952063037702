import {
  GET_DASHBOARD_DATA,
  DASHBOARD_BEGIN_LOADING,
  DASHBOARD_END_LOADING,
  WATCH_DASHBOARD_DATA,
} from "../actionTypes";
import { fetchDashboard } from "./action";
import { getDashboardCounters } from "../../store/services/adminDashboard.service";
import { put, takeEvery, call } from "redux-saga/effects";
const email = sessionStorage.getItem("email");
const company_code = sessionStorage.getItem("company_code") || "";

function* fetchDashboardAPI() {
  yield put({ type: DASHBOARD_BEGIN_LOADING });
  const response = yield call(getDashboardCounters, email, company_code);
  yield put(fetchDashboard(response.data.data));
  yield put({ type: DASHBOARD_END_LOADING });
}

export function* watcherDashboard() {
  yield takeEvery(WATCH_DASHBOARD_DATA, fetchDashboardAPI);
}
