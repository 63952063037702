import React, { createContext, useState, useEffect } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import {
  getDashboardAnnouncements,
  getDashboardCounters,
  getDashboardInvestorRequests,
} from "../store/services/adminDashboard.service";
import { getCompanyInfo } from "../store/services/companyInfo.service";
import { getTransactionTypes } from "../store/services/transaction.service";

export const DashboardContext = createContext();

export const DashboardProvider = (props) => {
  const [companyUsersCount, setCompanyUsersCount] = useState();
  const [shareholdersCount, setShareholdersCount] = useState();
  const [certificatesCount, setCertificatesCount] = useState(); //same for ongoing certificate number.
  const [physicalCount, setPhysicalCount] = useState();
  const [electronicCount, setElectronicCount] = useState();
  const [rightsSubscribedCount, setRightsSubscribedCount] = useState();
  const [totalRightsCount, setTotalRightsCount] = useState();
  const [outstandingSharesCount, setOutstandingSharesCount] = useState();
  const [lotSizeCount, setLotSizeCount] = useState();
  const [ongoingDistinctiveNumber, setOngoingDistinctiveNumber] = useState();
  const [bonusSharesAlloted, setBonusSharesAlloted] = useState();
  const [dividentDisbursed, setDividentDisbursed] = useState();
  const [corporateTransactionsData, setCorporateTransactionsData] = useState();

  const [investorRequests, setInvestorRequests] = useState();

  const [splitSharesApprovedCount, setSplitSharesApprovedCount] = useState(0);
  const [splitSharesPendingCount, setSplitSharesPendingCount] = useState(0);
  const [splitSharesRejectedCount, setSplitSharesRejectedCount] = useState(0);

  const [consolidateSharesApprovedCount, setConsolidateSharesApprovedCount] =
    useState(0);
  const [consolidateSharesPendingCount, setConsolidateSharesPendingCount] =
    useState(0);
  const [consolidateSharesRejectedCount, setConsolidateSharesRejectedCount] =
    useState(0);

  const [duplicateSharesApprovedCount, setDuplicateSharesApprovedCount] =
    useState(0);
  const [duplicateSharesPendingCount, setDuplicateSharesPendingCount] =
    useState(0);
  const [duplicateSharesRejectedCount, setDuplicateSharesRejectedCount] =
    useState(0);

  const [transferOfSharesApprovedCount, setTransferOfSharesApprovedCount] =
    useState(0);
  const [transferOfSharesPendingCount, setTransferOfSharesPendingCount] =
    useState(0);
  const [transferOfSharesRejectedCount, setTransferOfSharesRejectedCount] =
    useState(0);

  const [
    physicalToElectronicApprovedCount,
    setPhysicalToElectronicApprovedCount,
  ] = useState(0);
  const [
    physicalToElectronicPendingCount,
    setPhysicalToElectronicPendingCount,
  ] = useState(0);
  const [
    physicalToElectronicRejectedCount,
    setPhysicalToElectronicRejectedCount,
  ] = useState(0);

  const [
    electronicToPhysicalApprovedCount,
    setElectronicToPhysicalApprovedCount,
  ] = useState(0);
  const [
    electronicToPhysicalPendingCount,
    setElectronicToPhysicalPendingCount,
  ] = useState(0);
  const [
    electronicToPhysicalRejectedCount,
    setElectronicToPhysicalRejectedCount,
  ] = useState(0);

  const [rightSubscriptionApprovedCount, setRightSubscriptionApprovedCount] =
    useState(0);
  const [rightSubscriptionPendingCount, setRightSubscriptionPendingCount] =
    useState(0);
  const [rightSubscriptionRejectedCount, setRightSubscriptionRejectedCount] =
    useState(0);

  const [
    transferRightSharesApprovedCount,
    setTransferRightSharesApprovedCount,
  ] = useState(0);
  const [transferRightSharesPendingCount, setTransferRightSharesPendingCount] =
    useState(0);
  const [
    transferRightSharesRejectedCount,
    setTransferRightSharesRejectedCount,
  ] = useState(0);

  const [
    verificationTransferDeedApprovedCount,
    setVerificationTransferDeedApprovedCount,
  ] = useState(0);
  const [
    verificationTransferDeedPendingCount,
    setVerificationTransferDeedPendingCount,
  ] = useState(0);
  const [
    verificationTransferDeedRejectedCount,
    setVerificationTransferDeedRejectedCount,
  ] = useState(0);

  const [
    transmissionOfSharesApprovedCount,
    setTransmissionOfSharesApprovedCount,
  ] = useState(0);
  const [
    transmissionOfSharesPendingCount,
    setTransmissionOfSharesPendingCount,
  ] = useState(0);
  const [
    transmissionOfSharesRejectedCount,
    setTransmissionOfSharesRejectedCount,
  ] = useState(0);

  //states for announcements

  //states to store data returned in useEffect backend calls
  const [dashboardData, setDashboardData] = useState();

  const email = sessionStorage.getItem("email");
  const company_code = sessionStorage.getItem("company_code");

  //pieChart states
  const [outstandingShares, setOutstandingShares] = useState("");
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [faceValue, setFaceValue] = useState("");
  const [totalShares, setTotalShares] = useState("");
  const [lotSize, setLotSize] = useState("");
  const [treasuryShares, setTreasuryShares] = useState("");
  const [freeFloat, setFreeFloat] = useState("");
  const [preferenceShares, setPreferenceShares] = useState("");
  const [ordinaryShares, setOrdinaryShares] = useState("");
  const [nonVotingShares, setNonVotingShares] = useState("");
  const [redeemableShares, setRedeemableShares] = useState("");
  const [managementShares, setManagementShares] = useState("");

  const [companyNameTop, setCompanyNameTop] = useState("");
  const [companyLogoTop, setCompanyLogoTop] = useState("");

  // useEffect to get all values at mount for counters
  useEffect(async () => {
    const response = await getDashboardCounters(email, company_code);
    const transaction_types = await getTransactionTypes(email);
    setTransactionTypes(transaction_types?.data?.data);
    setCompanyUsersCount(response?.data?.data?.companyUsers);
    setShareholdersCount(response?.data?.data?.shareholderCount);
    setCorporateTransactionsData(
      response?.data?.data?.corporateTransactions.slice(0, 3)
    );
    setCertificatesCount(response?.data?.data?.certificatesCount);
    setPhysicalCount(response?.data?.data?.physicalShareholders);
    setElectronicCount(response?.data?.data?.electronicShareholders);
    setRightsSubscribedCount(response?.data?.data?.rightSubscribed);
    setTotalRightsCount(response?.data?.data?.totalRights);
    setOutstandingSharesCount(response?.data?.data?.outstandingShares);
    setLotSizeCount(response?.data?.data?.lotSize);
    setOngoingDistinctiveNumber(response?.data?.data?.disctinctiveCounter);
    setBonusSharesAlloted(response?.data?.data?.bonusShares);
    setDividentDisbursed(response?.data?.data?.dividendDisbursed);
  }, []);

  //useEffect to get announcements

  //useEffect for investor Requests
  useEffect(async () => {
    const response = await getDashboardInvestorRequests(email, company_code);

    if (response?.data?.data) {
      response.data.data.map((request) => {
        //split shares
        if (request.request_type === "SPL") {
          if (request.status === "APPROVED") {
            setSplitSharesApprovedCount(splitSharesApprovedCount + 1);
          }
          if (request.status === "PENDING") {
            setSplitSharesPendingCount(splitSharesPendingCount + 1);
          }
          if (request.status === "REJECTED") {
            setSplitSharesRejectedCount(splitSharesRejectedCount + 1);
          }
        }

        //consolidate shares

        if (request.request_type === "CON") {
          if (request.status === "APPROVED") {
            setConsolidateSharesApprovedCount(
              consolidateSharesApprovedCount + 1
            );
          }
          if (request.status === "PENDING") {
            setConsolidateSharesPendingCount(consolidateSharesPendingCount + 1);
          }
          if (request.status === "REJECTED") {
            setConsolidateSharesRejectedCount(
              consolidateSharesRejectedCount + 1
            );
          }
        }

        //duplicate shares

        if (request.request_type === "DUP") {
          if (request.status === "APPROVED") {
            setDuplicateSharesApprovedCount(duplicateSharesApprovedCount + 1);
          }
          if (request.status === "PENDING") {
            setDuplicateSharesPendingCount(duplicateSharesPendingCount + 1);
          }
          if (request.status === "REJECTED") {
            setDuplicateSharesRejectedCount(
              setDuplicateSharesRejectedCount + 1
            );
          }
        }

        //transfer of shares
        if (request.request_type === "TOS") {
          if (request.status === "APPROVED") {
            setTransferOfSharesApprovedCount(transferOfSharesApprovedCount + 1);
          }
          if (request.status === "PENDING") {
            setTransferOfSharesPendingCount(transferOfSharesPendingCount + 1);
          }
          if (request.status === "REJECTED") {
            setTransferOfSharesRejectedCount(transferOfSharesRejectedCount + 1);
          }
        }

        //physical to electronic
        if (request.request_type === "CEL") {
          if (request.status === "APPROVED") {
            setPhysicalToElectronicApprovedCount(
              physicalToElectronicApprovedCount + 1
            );
          }
          if (request.status === "PENDING") {
            setPhysicalToElectronicPendingCount(
              physicalToElectronicPendingCount + 1
            );
          }
          if (request.status === "REJECTED") {
            setPhysicalToElectronicRejectedCount(
              physicalToElectronicRejectedCount + 1
            );
          }
        }

        //electronic to physical
        if (request.request_type === "CPH") {
          if (request.status === "APPROVED") {
            setElectronicToPhysicalApprovedCount(
              electronicToPhysicalApprovedCount + 1
            );
          }
          if (request.status === "PENDING") {
            setElectronicToPhysicalPendingCount(
              electronicToPhysicalPendingCount + 1
            );
          }
          if (request.status === "REJECTED") {
            setElectronicToPhysicalRejectedCount(
              electronicToPhysicalRejectedCount + 1
            );
          }
        }

        //right subscription
        if (request.request_type === "RSUB") {
          if (request.status === "APPROVED") {
            setRightSubscriptionApprovedCount(
              rightSubscriptionApprovedCount + 1
            );
          }
          if (request.status === "PENDING") {
            setRightSubscriptionPendingCount(rightSubscriptionPendingCount + 1);
          }
          if (request.status === "REJECTED") {
            setRightSubscriptionRejectedCount(
              rightSubscriptionRejectedCount + 1
            );
          }
        }

        //transfer rights share
        if (request.request_type === "TOR") {
          if (request.status === "APPROVED") {
            setTransferRightSharesApprovedCount(
              transferRightSharesApprovedCount + 1
            );
          }
          if (request.status === "PENDING") {
            setTransferRightSharesPendingCount(
              transferRightSharesPendingCount + 1
            );
          }
          if (request.status === "REJECTED") {
            setTransferRightSharesRejectedCount(
              transferRightSharesRejectedCount + 1
            );
          }
        }

        //verification transfer deed

        if (request.request_type === "VTD") {
          if (request.status === "APPROVED") {
            setVerificationTransferDeedApprovedCount(
              verificationTransferDeedApprovedCount + 1
            );
          }
          if (request.status === "PENDING") {
            setVerificationTransferDeedPendingCount(
              verificationTransferDeedPendingCount + 1
            );
          }
          if (request.status === "REJECTED") {
            setVerificationTransferDeedRejectedCount(
              verificationTransferDeedRejectedCount + 1
            );
          }
        }

        //transmission of shares
        if (request.request_type === "TRS") {
          if (request.status === "APPROVED") {
            setTransmissionOfSharesApprovedCount(
              transmissionOfSharesApprovedCount + 1
            );
          }
          if (request.status === "PENDING") {
            setTransmissionOfSharesPendingCount(
              transmissionOfSharesPendingCount + 1
            );
          }
          if (request.status === "REJECTED") {
            setTransmissionOfSharesRejectedCount(
              transmissionOfSharesRejectedCount + 1
            );
          }
        }
      });
    }
  }, []);

  //useEffect to get Data for pieChart
  useEffect(async () => {
    const response = await getCompanyInfo(email, company_code);

    setOutstandingShares(response?.data?.data?.outstandingShares);
    setFaceValue(response?.data?.data?.face_value);
    setTotalShares(response?.data?.data?.total_shares);
    setLotSize(response?.data?.data?.allot_size);
    setTreasuryShares(response?.data?.data?.treasury_shares);
    setFreeFloat(response?.data?.data?.free_float);
    setPreferenceShares(response?.data?.data?.preference_shares);
    setOrdinaryShares(response?.data?.data?.ordinary_shares);
    setNonVotingShares(response?.data?.data?.non_voting_shares);
    setRedeemableShares(response?.data?.data?.redeemable_shares);
    setManagementShares(response?.data?.data?.management_shares);
    setCompanyNameTop(response?.data?.data?.company_name);
    setCompanyLogoTop(response?.data?.data?.logo);
  }, []);

  return (
    <DashboardContext.Provider
      value={{
        companyUsersCount,
        setCompanyUsersCount,
        shareholdersCount,
        setShareholdersCount,
        certificatesCount,
        setCertificatesCount,
        physicalCount,
        setPhysicalCount,
        electronicCount,
        setElectronicCount,
        rightsSubscribedCount,
        setRightsSubscribedCount,
        totalRightsCount,
        setTotalRightsCount,
        outstandingSharesCount,
        setOutstandingSharesCount,
        lotSizeCount,
        setLotSizeCount,
        ongoingDistinctiveNumber,
        setOngoingDistinctiveNumber,
        bonusSharesAlloted,
        setBonusSharesAlloted,
        dividentDisbursed,
        setDividentDisbursed,
        corporateTransactionsData,
        setCorporateTransactionsData,
        investorRequests,
        setInvestorRequests,
        splitSharesApprovedCount,
        setSplitSharesApprovedCount,
        splitSharesPendingCount,
        setSplitSharesPendingCount,
        splitSharesRejectedCount,
        setSplitSharesRejectedCount,
        consolidateSharesApprovedCount,
        setConsolidateSharesApprovedCount,
        consolidateSharesPendingCount,
        setConsolidateSharesPendingCount,
        consolidateSharesRejectedCount,
        setConsolidateSharesRejectedCount,
        duplicateSharesApprovedCount,
        setDuplicateSharesApprovedCount,
        duplicateSharesPendingCount,
        setDuplicateSharesPendingCount,
        duplicateSharesRejectedCount,
        setDuplicateSharesRejectedCount,
        transferOfSharesApprovedCount,
        setTransferOfSharesApprovedCount,
        transferOfSharesPendingCount,
        setTransferOfSharesPendingCount,
        transferOfSharesRejectedCount,
        setTransferOfSharesRejectedCount,
        physicalToElectronicApprovedCount,
        setPhysicalToElectronicApprovedCount,
        physicalToElectronicPendingCount,
        setPhysicalToElectronicPendingCount,
        physicalToElectronicRejectedCount,
        setPhysicalToElectronicRejectedCount,
        electronicToPhysicalApprovedCount,
        setElectronicToPhysicalApprovedCount,
        electronicToPhysicalPendingCount,
        setElectronicToPhysicalPendingCount,
        electronicToPhysicalRejectedCount,
        setElectronicToPhysicalRejectedCount,
        rightSubscriptionApprovedCount,
        setRightSubscriptionApprovedCount,
        rightSubscriptionPendingCount,
        setRightSubscriptionPendingCount,
        rightSubscriptionRejectedCount,
        setRightSubscriptionRejectedCount,
        transferRightSharesApprovedCount,
        setTransferRightSharesApprovedCount,
        transferRightSharesPendingCount,
        setTransferRightSharesPendingCount,
        transferRightSharesRejectedCount,
        setTransferRightSharesRejectedCount,
        verificationTransferDeedApprovedCount,
        setVerificationTransferDeedApprovedCount,
        verificationTransferDeedPendingCount,
        setVerificationTransferDeedPendingCount,
        verificationTransferDeedRejectedCount,
        setVerificationTransferDeedRejectedCount,
        transmissionOfSharesApprovedCount,
        setTransmissionOfSharesApprovedCount,
        transmissionOfSharesPendingCount,
        setTransmissionOfSharesPendingCount,
        transmissionOfSharesRejectedCount,
        setTransmissionOfSharesRejectedCount,
        dashboardData,
        setDashboardData,
        outstandingShares,
        setOutstandingShares,
        faceValue,
        setFaceValue,
        totalShares,
        setTotalShares,
        lotSize,
        setLotSize,
        treasuryShares,
        setTreasuryShares,
        freeFloat,
        setFreeFloat,
        preferenceShares,
        setPreferenceShares,
        ordinaryShares,
        setOrdinaryShares,
        nonVotingShares,
        setNonVotingShares,
        redeemableShares,
        setRedeemableShares,
        managementShares,
        setManagementShares,
        companyNameTop,
        setCompanyNameTop,
        companyLogoTop,
        setCompanyLogoTop,
        transactionTypes,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};
