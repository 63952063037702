import React, { Fragment, useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import man from "../../../assets/images/dashboard/user.png";
import { User, Mail, Lock, Settings, LogOut } from "react-feather";
import { firebase_app } from "../../../data/config";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { EditProfile, Inbox, LockScreen } from "../../../constant";
import { logoutCentral, logoutMain } from "store/services/auth.service";
import { getCompanyById } from "store/services/company.service";
import { toast } from "react-toastify";

const UserMenu = ({ history }) => {
  const email = sessionStorage.getItem('email');
  const company_code = sessionStorage.getItem('company_code');
  const [profile, setProfile] = useState("");
  // const { company_data, company_data_loading } = useSelector(
  //   (data) => data.Company
  // );
  const [company_data, setCompany_Data] = useState({});
  // auth0 profile
  const { logout } = useAuth0();
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"));

  const getCompanyData = async () => {
    try {
      const response = await getCompanyById(email, company_code);
      if (response.status === 200) {
        setCompany_Data(response.data.data);
      } else {
        setCompany_Data({});
      }
    } catch (error) {
      if (error.response !== undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  }

  useEffect(() => {
    getCompanyData();
    setProfile(localStorage.getItem("profileURL") || man);
  }, []);

  const logoutApp = async () => {

    try {
      const mainResponse = await logoutMain(email);
      if (mainResponse.status === 200) {
        const response = await logoutCentral(email);
        if (response.status === 200) {
          handleLogout();
        } else {
          toast.error(response.data.message);
        }
      } else {
        toast.error(mainResponse.data.message);
      }
    } catch (error) {
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message)
      }
    }

  }

  const handleLogout = () => {
    sessionStorage.clear();
    window.location.replace(`${process.env.PUBLIC_URL}/login`);
  };

  return (
    <Fragment>
      <li className="onhover-dropdown">
        <div className="media align-items-center">
          <img
            className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
            src={
              company_data?.logo
                ? company_data?.logo
                : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkBAMAAACCzIhnAAAAG1BMVEXu7u4AAACysrI7OzvQ0NCUlJR3d3dZWVloaGjDwUyOAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAmklEQVRYhe3RQQrCMBCF4YdkkmsUvIIHmFXXRbS67MLsXeT+OFM0SlUal8L7oIU/kDJJASIiIiJqE+zR1wVd9MJWJReMJ63t8ex3MmqPFCZca1uU2p+GirpHlA4TNoO9rC2y91dRL5BecQCOSb2j4jx3wxbZoXHLYzCkPHfDYPX4RQbvteP7V++XHBSd99olu19/JREREdGfuAHXNB+Jwuby0AAAAABJRU5ErkJggg=="
            }
            alt={company_data?.company_name || "Company Logo"}
          />
          {/* <div className="dotted-animation">
            <span className="animate-circle"></span>
            <span className="main-circle"></span>
          </div> */}
        </div>
        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
          <li>
            <Link
              to="#"
              onClick={(e) => {
                // history.push(`${process.env.PUBLIC_URL}/company-info`);
                // sessionStorage.setItem("selectedCompany", JSON.stringify(company_data));
              }
              }
            >
              {company_data?.company_name}
            </Link>
          </li>
          <div className="dropdown-divider"></div>
          <li>
            <Link to="#">{`${sessionStorage.getItem("name")}`}</Link>
            {`(${sessionStorage.getItem("email")})`}
          </li>
          <div className="dropdown-divider"></div>
          <li>
            <a
              // onClick={authenticated ? Logout_From_Auth0 : Logout_From_Firebase}
              onClick={logoutApp}
            //   href="#javascript"
            >
              <LogOut /> {"Log out"}
            </a>
          </li>
        </ul>
      </li>
    </Fragment>
  );
};

export default withRouter(UserMenu);
