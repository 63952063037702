import React from "react";
import loader from "../../assets/images/dcc_loader.svg";
import styled from "styled-components";
const Spinner = ({ showText }) => {
  return (
    <div className="row d-flex justify-content-center">
      <div className="col-md-6">
        <center>
          {showText && (
            <h6 className="mb-0 text-nowrap">
              <b>{"Please Wait"}</b>
            </h6>
          )}
          <WrapperForResponsive>
          <img alt="dcc_loader" className="loader-image1" src={loader} />
          </WrapperForResponsive>
          {/* <div className="d-flex justify-content-center">
            <div className="loader-box mx-auto">
              <div className="loader">
                <div className="line bg-primary"></div>
                <div className="line bg-primary"></div>
                <div className="line bg-primary"></div>
                <div className="line bg-primary"></div>
              </div>
            </div>
          </div> */}
        </center>
      </div>
    </div>
  );
};

export default Spinner;


const WrapperForResponsive = styled.div`
@media (max-width: 767px) {
  .loader-image1 {
    margin-top: 100px;
    margin-bottom: 70px;
    height:100%
  }
}
`;
