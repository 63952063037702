import {
  GET_COMPANY,
  COMPANY_BEGIN_LOADING,
  COMPANY_END_LOADING,
} from "../../../redux/actionTypes";
const initial_state = {
  company_data: {},
  company_data_loading: false,
};
export default (state = initial_state, action) => {
  switch (action.type) {
    case GET_COMPANY:
      return { ...state, company_data: action.payload };

    case COMPANY_BEGIN_LOADING:
      return { ...state, company_data_loading: true };
    case COMPANY_END_LOADING:
      return { ...state, company_data_loading: false };
    default:
      return state;
  }
};
