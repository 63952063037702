import {
  COMPANY_BEGIN_LOADING,
  COMPANY_END_LOADING,
  GET_COMPANY,
  WATCH_COMPANY,
} from "../../../redux/actionTypes";

export const watchCompany = () => ({ type: WATCH_COMPANY });

export const beginCompanyLoading = () => ({
  type: COMPANY_BEGIN_LOADING,
});

export const endCompanyLoading = () => ({
  type: COMPANY_END_LOADING,
});

export const fetchCompany = (company) => ({
  type: GET_COMPANY,
  payload: company,
});
