import axios from "axios";
import Config from "../../config";

export const sendOTP = async (email) => {
  // const url = `${Config.baseUrl}/user/send-otp`;
  const url = `${Config.authBaseUrl}user/generate-otp`;
  // const via = "email";
  try {
    // const result = await axios.post(url, { email, via });
    const result = await axios.post(url, { email });
    return result;
  } catch (error) {
  }
};

export const loginViaOtp = async (email, otp) => {
  // const url = `${Config.baseUrl}/user/login-via-otp`;
  const url = `${Config.authBaseUrl}user/verify-otp-issuer`;
  try {
    const result = await axios.post(url, {
      email,
      otp,
      user_type: "COMPANYUSER",
    });
    return result;
  } catch (error) {
  }
};
