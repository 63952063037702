import React, { useState, useEffect } from "react";
import Select from "react-select";
import NumberFormat from "react-number-format";
import InputMask from "react-input-mask";
import { darkStyle, disabledStyles } from "../../defaultStyles";
import styled from "styled-components";
import ToggleButton from "react-toggle-button";

export const AuthorizedPersonItem = ({
  num,
  startCalculation,
  calculated,
  roles,
  ap_name,
  ap_email,
  ap_contact,
  ap_role,
  editPerson,
  activeuser,
  roless,
  dates,
  reasons
}) => {
  const [name, setName] = useState(ap_name || "");
  const [email, setEmail] = useState(ap_email || "");
  const [contact, setContact] = useState(ap_contact || "");
  const [rolessa, setRole] = useState(roless || "");
  const [reason, setReason] = useState(reasons || '')
  const [date, setDate] = useState(dates || null)
  const [active, setactive] = useState(activeuser == 'Y' ? true : activeuser == 'N' ? false : false); //done

  const [governanceRole] = useState([
    { label: 'CEO' },
    { label: 'chairman/Non-Ex-director' },
    { label: 'chairman/independent-director' },
    { label: 'Shareholder/Non-Ex-director' },
    { label: 'shareholder/independent-director' },
  ])
  useEffect(() => {
    if (calculated === true) {
      startCalculation({
        name,
        email,
        contact,
        role: 'COMPANY_ROLE',
        active: active ? 'Y' : 'N',
        reason,
        date
      });
    }
  }, [calculated]);
  const borderRadiusStyle = { borderRadius: 2 };
  return (
    <>
      <tr>
        <td scope="col">
          <b>{num}</b>
        </td>
        <td>
          <input
            type="text"
            name="name"
            id="name"
            className="form-control"
            style={{ minWidth: '140px' }}
            value={name}
            onChange={(e) => setName(e.target.value)}
            readOnly={calculated && !editPerson}
          />
        </td>
        <td>
          <input
            type="email"
            name="email"
            id="email"
            className="form-control"
            style={{ minWidth: '140px' }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            readOnly={calculated && !editPerson}
          />
        </td>
        <td>
          <input
            type="text"
            name="contact"
            id="contact"
            className="form-control"
            style={{ minWidth: '140px' }}
            maxLength={16}
            value={contact}
            onChange={(e) => {
              if (e.target.value.match(/^\d+$/)) setContact(e.target.value)
            }}
            readOnly={calculated && !editPerson}
          />
        </td>

        <td>
          <input
            type="text"
            name="reason"
            id="reason"
            className="form-control"
            style={{ minWidth: '140px' }}
            // maxLength={16}
            value={reason}
            disabled={active}
            onChange={(e) => {
              setReason(e?.target?.value)
            }}
            readOnly={calculated && !editPerson}
          />

        </td>
        <td>
          <input
            type="date"
            name="date"
            id="date"
            className="form-control"
            style={{ minWidth: '140px' }}
            // maxLength={16}
            disabled={active}
            value={date}
            onChange={(e) => {
              setDate(e?.target?.value)
            }}
            readOnly={calculated && !editPerson}
          />

        </td>
        <td>
          <ToggleButton
            name="active"
            value={active}
            thumbStyle={borderRadiusStyle}
            trackStyle={borderRadiusStyle}
            onToggle={() => {
              if (active) {
                setactive(false);
              } else {
                setactive(true);
              }
            }}
          />
        </td>
      </tr>
    </>
  );
};
const SelectWrapper = styled(Select)`

max-width: 250px !important;
min-width: 230px !important;
`;