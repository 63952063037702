import {
  GET_DASHBOARD_DATA,
  DASHBOARD_BEGIN_LOADING,
  DASHBOARD_END_LOADING,
  WATCH_DASHBOARD_DATA,
} from "../actionTypes";

export const watchDashboard = () => ({ type: WATCH_DASHBOARD_DATA });

export const beginDashboardLoading = () => ({ type: DASHBOARD_BEGIN_LOADING });

export const endDashboardLoading = () => ({ type: DASHBOARD_END_LOADING });

export const fetchDashboard = (dashboard) => ({
  type: GET_DASHBOARD_DATA,
  payload: dashboard,
});
